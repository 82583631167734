import React, { useCallback, useEffect, useRef } from "react";

import Api from "app/js/api";
import { Entity, LabelVersionLabels, LabelVersionMode } from "app/js/types";
import { screenSmall } from "app/js/util";
import { match } from "react-router";
import { History, Location } from "history";

import { useSafeState, useCanvas } from "app/js/hooks";
import styles from "./Labelling.scss";

import {
  prepareObject,
  exportObject,
} from "app/pages/LabellingJobs/Labelling/helper/helper";
import ToolMenu from "./components/ToolMenu/ToolMenu";
import CreateRect from "./components/CreateRect";
import CreateCircle from "./components/CreateCircle";
import CreatePolygon from "./components/CreatePolygon";
import EditPolygon from "./components/EditPolygon";
import EntityList from "./components/EntityList/EntityList";
import { useUserStore } from "app/js/stores";
import SelectLabelVersion from "app/components/Selects/SelectLabelVersion";
import { Action, LabellingImage } from "../types";

const loadRecommendations = async (
  entities: Entity[],
  jobId: number,
  frameId: any,
  indexerId: any,
  setRecommendations: (arg0: any) => void,
) => {
  try {
    const response = await Api.job(jobId)
      .framesToLabel(frameId)
      .recommendations({
        entities: entities,
        indexer_id: indexerId,
      });
    setRecommendations(JSON.parse(response.data.results));
  } catch (error) {
    console.warn("Could not fetch Recommendations");
  }
};

interface LabellingSearchParams {
  mode: string;
  redirect: string;
  redirect_parameters: string;
  label_version: string;
}

interface LabellingProps {
  match: match<{
    frameId: string;
    labellingJobId: string;
  }>;
  history: History<LabellingSearchParams>;
  location: Location<LabellingSearchParams>;
}

const Labelling: React.FC<LabellingProps> = ({ match, location, history }) => {
  const [user] = useUserStore();
  const jobId = parseInt(match.params.labellingJobId);
  const frameId = match.params.frameId;
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [datasetId, setDatasetId] = useSafeState<number | null>(null);
  const [indexerId, setIndexerId] = useSafeState<number | null>(null);
  const inputLabel = useRef(null);

  const params = new URLSearchParams(location.search);

  // mode
  const mode = (params.get("mode") || "latest") as LabelVersionMode;

  // redirect
  const redirect = params.get("redirect") || "";
  const redirect_parameters = params.get("redirect_parameters") || "";

  // versions
  const labelVersion = parseInt(params.get("label_version")) || null;
  // remaining frames
  const [_remaining, setRemaining] = useSafeState(null);

  const initFrame = async () => {
    const response = await Api.job(jobId).framesToLabel().show();

    const newFrame = response.data.frame;
    let newId = null;
    if (newFrame) {
      newId = response.data.frame.id;
    }
    if (response.data.frame !== null) {
      setDatasetId(response.data.frame.dataset);
      setRemaining(response.data.remaining);
    }

    if (newId) {
      return history.push(`/labelling-jobs/${jobId}/frame/${newId}`);
    } else if (redirect) {
      return history.push(
        `${redirect}?${redirect_parameters.split(",").join("&")}`,
      );
    }

    // return back to List
    if (response.data.frame === null) {
      return history.push(`/labelling-jobs/${jobId}`);
    }
  };

  /*
   * LOAD LABELS
   */
  const [labels, setLabels] = useSafeState<LabelVersionLabels>({});
  useEffect(() => {
    const loadLabels = async () => {
      const response = await Api.labelversion().labels({
        job_ids: [jobId],
        mode: "user_latest",
      });
      setLabels(response.data.results);
    };

    if (datasetId) {
      loadLabels();
    }
  }, [datasetId, jobId, frameId, user.data, setLabels]);

  /*
   * LOAD JOB IMAGE TO LABEL
   */
  const [image, setImage] = useSafeState<LabellingImage>({
    image: null,
    height: null,
    width: null,
  });
  const [entities, setEntities] = useSafeState<Entity[]>([]);
  const stringifiedEntities = JSON.stringify(entities); // to use for hook dependencies

  const loadFrame = useCallback(async () => {
    setLoading(true);
    let entities = null;
    if (labelVersion) {
      const response = await Api.labelversion(labelVersion).show(true);
      entities = response.data.entities;
    } else {
      let response = await Api.labelversion().all({
        frame_ids: [parseInt(frameId)],
        job_ids: [jobId],
        include_entities: true,
        mode: mode,
        order_by: "-create_time",
      });
      if (
        (mode === "auto" || mode === "latest") &&
        response.data.results.length === 0
      ) {
        response = await Api.labelversion().all({
          frame_ids: [parseInt(frameId)],
          include_entities: true,
          mode: "auto",
          order_by: "-create_time",
        });
      }
      if (response.data.results.length > 0) {
        entities = response.data.results[0].entities;
      }
    }
    if (entities) {
      const tempEntities = entities.map((entry) => {
        if (entry.aabox === null) {
          delete entry.aabox;
        }
        if (entry.box === null) {
          delete entry.box;
        }
        if (entry.circle === null) {
          delete entry.circle;
        }
        if (entry.contour === null) {
          delete entry.contour;
        }
        return entry;
      });
      setEntities(tempEntities);
    } else {
      setEntities([]);
    }

    const responseImage = await Api.frame(frameId).show();
    if (responseImage.data.image !== null) {
      setImage({
        image: responseImage.data.image.image,
        height: responseImage.data.image.height,
        width: responseImage.data.image.width,
      });
      setDatasetId(responseImage.data.dataset);
    }
    const getIndexerIdResponse = await Api.job(jobId).show();
    setIndexerId(getIndexerIdResponse.data.indexer);
    setLoading(false);
  }, [
    frameId,
    jobId,
    labelVersion,
    mode,
    setDatasetId,
    setEntities,
    setImage,
    setIndexerId,
    setLoading,
  ]);
  useEffect(() => {
    loadFrame();
  }, [frameId, labelVersion, loadFrame, mode, user.data]);

  /*
   * CANVAS INIT
   */
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [action, setAction] = useSafeState<Action>({ edit: false, type: null });
  const [canvas, _setCanvas] = useCanvas({
    image,
    canvasRef,
    canvasContainerRef,
    action,
    backgroundCallback: () => {
      setLoading(false);
    },
  });

  /*
   * DRAW ENTITIES
   */
  const [activeEntityId, setActiveEntityId] = useSafeState<number>(undefined);
  // History of Actions
  const [changeLog, setChangeLog] = useSafeState<Entity[][]>([]);
  const [logPosition, setLogPosition] = useSafeState<number>(-1);
  const [browsing, setBrowsing] = useSafeState(false);
  const [trackAllChanges, setTrackAllChanges] = useSafeState<Entity[][] | null>(
    null,
  );

  const activeObject = canvas?.getActiveObject();

  useEffect(() => {
    if ((changeLog || logPosition) && activeEntityId) {
      setTrackAllChanges(changeLog);
    }
  }, [changeLog, logPosition, activeEntityId, setTrackAllChanges]);
  const stopLabelling = () => {
    if (trackAllChanges === null || trackAllChanges.length === 0) {
      return history.push(`/labelling-jobs/${jobId}`);
    } else {
      const result = confirm("Are you sure you want to exit without saving?");
      if (result) {
        return history.push(`/labelling-jobs/${jobId}`);
      }
    }
  };

  useEffect(() => {
    if (canvas && entities) {
      // save state to change log without label
      const state = JSON.stringify(
        entities.map(({ label, ...keepAttrs }) => keepAttrs),
      );
      const noLabelChangeLog = changeLog.map((entry) =>
        JSON.stringify(entry.map(({ label, ...keepAttrs }) => keepAttrs)),
      );
      // did something change
      if (state !== noLabelChangeLog[changeLog.length - 1]) {
        // did it change because the user is browsing through history
        if (!browsing && !(changeLog.length === 0 && state === "[]")) {
          setChangeLog((prev) => {
            // state change, first check if we have a invalid future
            if (logPosition !== -1 && logPosition < prev.length - 1) {
              // cut away invalid future
              prev = prev.slice(0, logPosition + 1);
            }
            setLogPosition(prev.length);
            return [...prev, entities];
          });
        }
      }

      // remove all objects
      canvas.remove(...canvas.getObjects());

      // we need to add active element last to canvas
      let activeElement;

      // draw them again
      entities.forEach((entity) => {
        const element = prepareObject(entity, canvas.getZoom());
        if (element) {
          // set active Entity
          if (element.data?.id === activeEntityId) {
            activeElement = element;
          } else {
            canvas.add(element);
          }
        }
      });

      if (activeElement) {
        canvas.add(activeElement);
        canvas.setActiveObject(activeElement);
      }
      // Render all Entities
      canvas.renderAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // activeEntityId, // breaks entity creation if mentioned. fixme: find, why
    browsing,
    canvas,
    changeLog,
    loading,
    logPosition,
    setChangeLog,
    setLogPosition,
    stringifiedEntities, // instead of `entities`
  ]);

  // focus when activeId changes
  useEffect(() => {
    // set the focus to input
    if (inputLabel.current) {
      inputLabel.current.focus();
      setAction({ edit: false, type: null });
    }
    // render active Elements
    if (canvas && activeEntityId) {
      const objects = canvas.getObjects();
      const activeObject = objects.find((e) => e.data?.id === activeEntityId);
      canvas.setActiveObject(activeObject);
      canvas.renderAll();
    }
  }, [activeEntityId, canvas, setAction]);

  // Entity related Event Listeners
  const [recommendations, setRecommendations] = useSafeState<string[][]>([]);

  useEffect(() => {
    const objectModified = (event) => {
      setBrowsing(false);
      const updatedEntities = entities.map((entity) => {
        // circle/rect/polygon sync
        if (entity.id === event.target.data?.id) {
          const changedEntity = exportObject(event.target);
          entity = { ...entity, ...changedEntity };
        }
        // polygon sync (draggablecircle has targetId)
        if (event.target.data?.targetId) {
          if (entity.id === event.target.data?.targetId) {
            const polygon = canvas
              .getObjects("Polygon")
              .find((obj) => obj.data?.id === event.target.data?.targetId);
            const changedEntity = exportObject(polygon);
            entity = { ...entity, ...changedEntity };
          }
        }

        return entity;
      });
      setEntities(updatedEntities);
      loadRecommendations(
        updatedEntities,
        jobId,
        frameId,
        indexerId,
        setRecommendations,
      );

      if (inputLabel.current) {
        inputLabel.current.focus();
      }
    };

    const setActiveElementHandler = (event) => {
      // either id or target
      const id = event.target.data?.targetId
        ? event.target.data?.targetId
        : event.target.data?.id;
      setActiveEntityId(id);
    };

    const cleared = (event) => {
      setActiveEntityId(null);
      if (event.deselected) {
        canvas.sendToBack(event.deselected[0]);
      }
    };

    if (canvas) {
      if (entities.length) {
        canvas.on("object:modified", objectModified);
        canvas.on("selection:created", setActiveElementHandler);
        canvas.on("selection:updated", setActiveElementHandler);
        canvas.on("selection:cleared", cleared);
      }
      return () => {
        canvas.off("object:modified", objectModified);
        canvas.off("selection:created", setActiveElementHandler);
        canvas.off("selection:updated", setActiveElementHandler);
        canvas.off("selection:cleared", cleared);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvas,
    stringifiedEntities, // instead of `entities`
    indexerId,
    entities.length,
    setBrowsing,
    setEntities,
    setActiveEntityId,
    jobId,
    frameId,
    setRecommendations,
  ]);

  const createdAction = (object) => {
    setBrowsing(false);
    object.label = "";
    setEntities([...JSON.parse(JSON.stringify(entities)), object]);
    setActiveEntityId(object.id);
    setAction({ edit: false, type: null });

    loadRecommendations(
      [...JSON.parse(JSON.stringify(entities)), object],
      jobId,
      frameId,
      indexerId,
      setRecommendations,
    );
  };

  useEffect(() => {
    if (canvas) {
      // set cursor to crosshair when drawing a shape
      canvas.defaultCursor = action.type ? "crosshair" : "default";
      // deselect active object when drawing a shape to prevent shape drawing overlapping
      if (!activeEntityId) {
        canvas.discardActiveObject();
      }
    }
  }, [action, activeEntityId, canvas]);

  const keydown = (event) => {
    if (event.ctrlKey) {
      switch (event.keyCode) {
        case 89:
          event.preventDefault();
          goForward();
          return;
        case 90:
          event.preventDefault();
          goBack();
          return;
        default:
          return;
      }
    }
    if (event.altKey) {
      switch (event.keyCode) {
        case 82:
          event.preventDefault();
          setAction({ edit: true, type: "Rect" });
          return;
        case 67:
          event.preventDefault();
          setAction({ edit: true, type: "Circle" });
          return;
        case 80:
          event.preventDefault();
          setAction({ edit: true, type: "Polygon" });
          return;
        case 8:
          if (activeObject) {
            // remove from entities list
            const updatedEntities = entities.filter(
              (obj) => obj.id !== activeObject?.data?.id,
            );
            setEntities(updatedEntities);
          }
          return;
        default:
          setAction({ edit: false, type: null });
      }
    }
  };

  const typeHandler = (event, id, value = null) => {
    const validation = /^$|[a-zA-ZÄÖÜäöü0-9_. *]+$/;
    const text = value || event.target.value;

    if (!validation.test(text)) {
      return false;
    }
    // change the reference object
    const entity = entities.find((obj) => obj.id === id);
    entity.label = text;
    setActiveEntityId(entity.id);
    setEntities(JSON.parse(JSON.stringify(entities)));
  };

  // NAVIGATION
  const skip = async () => {
    if (
      logPosition > 0 &&
      !confirm("Changes will not be saved when skipping. Skip anyway?")
    ) {
      return;
    }
    await Api.job(jobId).framesToLabel(parseInt(frameId)).skip();
    prepareNextImage();
  };

  const next = async () => {
    // filter out null labels
    const prep = entities.filter((entry) => entry.label !== "");

    if (
      prep.length !== entities.length &&
      !confirm(
        `${
          entities.length - prep.length
        } shape(s) do not have labels and will be not be saved`,
      )
    ) {
      return;
    }

    await Api.job(jobId).framesToLabel(parseInt(frameId)).store({
      entities: prep,
    });
    window["matomo"].push(["trackGoal", 2]);
    prepareNextImage();
  };

  const prepareNextImage = () => {
    if (redirect) {
      return history.push(
        `${redirect}?${redirect_parameters.split(",").join("&")}`,
      );
    }

    // reset Log
    setChangeLog([]);
    setLogPosition(-1);

    // reset recommendations
    setRecommendations([]);

    // reset Entities on next Frame
    setEntities([]);

    // load next frame
    initFrame();
  };

  const goBack = () => {
    setBrowsing(true);
    if (logPosition > -1 && changeLog[logPosition - 1]) {
      const entityState = changeLog[logPosition - 1].map((item) => {
        return {
          ...item,
        };
      });
      setEntities([...entityState]);
      setLogPosition((prev) => prev - 1);
    }
  };
  const goForward = () => {
    setBrowsing(true);
    if (logPosition < changeLog.length - 1 && changeLog[logPosition + 1]) {
      const entityState = changeLog[logPosition + 1].map((item) => {
        return {
          ...item,
        };
      });
      setEntities([...entityState]);
      setLogPosition((prev) => prev + 1);
    }
  };

  // make sure they have the width to label
  // This should go after all `useEffect`, because otherwise it would be considered as a conditional rendering
  // fixme: this still does not re-render page on screen size change as one might assume
  if (screenSmall()) {
    return <p>Please use this Feature on Desktop Screens only.</p>;
  }
  return (
    <div className={styles.container} onKeyDown={keydown} tabIndex={0}>
      <ToolMenu
        className={styles.header}
        action={action}
        setAction={setAction}
        goBack={logPosition <= 0 ? false : goBack}
        goForward={logPosition === changeLog.length - 1 ? false : goForward}
        next={next}
        skip={skip}
        loading={loading}
        stopLabelling={stopLabelling}
        imageUrl={image ? image.image : null}
        setActiveEntityId={setActiveEntityId}
      />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <EntityList
            entities={entities}
            setEntities={setEntities}
            activeEntityId={activeEntityId}
            setActiveEntityId={setActiveEntityId}
            typeHandler={typeHandler}
            inputLabel={inputLabel}
            recommendations={recommendations}
            labels={labels}
            loading={loading}
            showModeSelect={labelVersion == null}
          >
            <SelectLabelVersion jobId={jobId} frameId={frameId} />
          </EntityList>
          <div ref={canvasContainerRef}>
            <canvas ref={canvasRef} width={0} height={0} />
          </div>
          {action.type === "Rect" && (
            <CreateRect canvas={canvas} createdAction={createdAction} />
          )}
          {action.type === "Circle" && (
            <CreateCircle canvas={canvas} createdAction={createdAction} />
          )}
          {action.type === "Polygon" && (
            <CreatePolygon canvas={canvas} createdAction={createdAction} />
          )}
          {/* Check for canvas size too, because canvas may exist but be uninitialized */}
          {canvas && canvas.height && <EditPolygon canvas={canvas} />}
        </div>
      </div>
    </div>
  );
};

export default Labelling;
