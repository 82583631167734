import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";

import Api from "app/js/api";
import Button from "app/components/Buttons/Button";
import TaskStatus from "app/components/TaskStatus/TaskStatus";
import FormComponent from "app/components/FormComponent/FormComponent";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { createFilter } from "react-select";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import Card from "app/components/Card/Card";
import CardList from "app/components/CardList/CardList";
import { useHistoryParamsPush, useParams, useSafeState } from "app/js/hooks";

import styles from "./DatasetDetailDownload.scss";
import { Frame, LabelVersionMode, Option } from "app/js/types";
import { CardImage } from "app/components/Card";

type Qualification = "qualified" | "unqualified" | "any";

export default function DatasetDetailDownload() {
  const match = useRouteMatch<{ id: string }>();
  const datasetId = parseInt(match.params.id);

  const [loadingLabellingJobs, setLoadingLabellingJobs] = useState(true);
  const modeOptions = [
    { value: "user_latest", label: "User Latest" },
    { value: "latest", label: "Latest" },
    { value: "auto", label: "Auto" },
  ];
  const qualificationOptions = [
    { value: "qualified", label: "Qualified" },
    { value: "unqualified", label: "Unqualified" },
    { value: "any", label: "Any latest" },
  ];

  const customFilter = createFilter({ matchFrom: "start" });

  const [mode, setMode] = useSafeState<LabelVersionMode>("user_latest");
  const [qualification, setQualification] = useSafeState<Qualification>(
    "qualified",
  );
  const [skipEmpty, setSkipEmpty] = useSafeState(true);
  const [includeImages, setIncludeImages] = useSafeState(true);
  const [includeUnlabelledImages, setIncludeUnlabelledImages] = useSafeState(
    false,
  );
  const [
    removeNotIncludedEntities,
    setRemoveNotIncludedEntities,
  ] = useSafeState(false);
  const [jobs, setJobs] = useSafeState(null);
  const [jobId, setJobId] = useSafeState(null);
  const [cameras, setCameras] = useSafeState(null);
  const [loadingCameras, setLoadingCameras] = useSafeState(true);
  const [cameraId, setCameraId] = useSafeState(null);

  const [downloadTask, setDownloadTask] = useState(null);
  const [downloadLink, setDownloadLink] = useState(undefined);

  const [frameBeforeDate, setFrameBeforeDate] = useState(null);
  const [frameAfterDate, setFrameAfterDate] = useState(null);

  const [labellingBeforeDate, setLabellingBeforeDate] = useState(null);
  const [labellingAfterDate, setLabellingAfterDate] = useState(null);

  const [labels, setLabels] = useSafeState([]);
  const [labelOptions, setLabelOptions] = useSafeState([]);
  const [loadingLabelOptions, setLoadingLabelOptions] = useSafeState(false);

  const [loading, setLoading] = useSafeState(false);
  const [frames, setFrames] = useSafeState<Frame[]>([]);
  const PAGE_LENGTH = 10;
  const params = useParams();
  const paramsPage = params.get("page");
  const offset =
    paramsPage === null ? 0 : (parseInt(paramsPage) - 1) * PAGE_LENGTH;
  const [count, setCount] = useSafeState(0);

  const [
    archiveGenerationInProgress,
    setArchiveGenerationInProgress,
  ] = useSafeState(false);

  let qualified = null;
  if (mode === "user_latest") {
    if (qualification === "qualified") {
      qualified = true;
    } else if (qualification === "unqualified") {
      qualified = false;
    }
  }

  const downloadTaskFinished = useCallback(
    (data) => {
      setDownloadLink(data.result);
      setArchiveGenerationInProgress(false);
    },
    [setArchiveGenerationInProgress, setDownloadLink],
  );

  const downloadTaskFailed = useCallback(() => {
    setArchiveGenerationInProgress(false);
  }, [setArchiveGenerationInProgress]);

  const loadJobs = useCallback(async () => {
    setLoadingLabellingJobs(true);
    try {
      const response = await Api.job().all({
        limit: 500,
        dataset_id: datasetId,
      });
      const newJobs = response.data.results.map((job) => {
        return { value: job.id, label: job.name };
      });
      setJobs(newJobs);
      setLoadingLabellingJobs(false);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId]);

  useEffect(() => {
    if (mode === "user_latest") {
      loadJobs();
    }
  }, [loadJobs, mode]);

  useEffect(() => {
    const loadCameras = async () => {
      setLoadingCameras(true);
      try {
        const response = await Api.stream().all({
          limit: 500,
          dataset_id: datasetId,
        });
        const newCameras = response.data.results.map((camera) => {
          return { value: camera.stream, label: camera.name };
        });
        setCameras(newCameras);
        setLoadingCameras(false);
      } catch (error) {
        console.error(error);
      }
    };
    loadCameras();
  }, [datasetId, setCameras, setLoadingCameras]);

  const onGenerateDatasetDownload = async () => {
    setArchiveGenerationInProgress(true);
    setDownloadLink(undefined);
    try {
      const response = await Api.dataset(datasetId).download({
        job_id: jobId,
        stream_id: cameraId,
        frame_created_before: frameBeforeDate ? frameBeforeDate : null,
        frame_created_after: frameAfterDate ? frameAfterDate : null,
        labelling_created_before: labellingBeforeDate
          ? labellingBeforeDate
          : null,
        labelling_created_after: labellingAfterDate ? labellingAfterDate : null,
        mode: mode === "user_latest" ? "any_user_latest" : mode,
        qualified: qualified,
        skip_empty: skipEmpty,
        include_images: includeImages,
        include_unlabelled_images: includeUnlabelledImages,
        or_labels: labels.map((l) => l.value),
        remove_not_included_entity: removeNotIncludedEntities,
      });
      setDownloadTask(response.data.results.task);
    } catch (error) {
      console.error(error);
      setArchiveGenerationInProgress(false);
    }
  };

  const resetOffset = () => {
    // Reset to the first page on any filter change to avoid requesting pages that exceed maximum
    params.set("page", "1");
  };

  const updateHistoryParams = useHistoryParamsPush();
  const updateParams = () => {
    resetOffset();
    updateHistoryParams(params);
  };

  const labelsStr = JSON.stringify(labels.map((l) => l.value));
  useEffect(() => {
    const loadImageVersions = async () => {
      setLoading(true);
      if (mode == null) {
        return;
      }
      setFrames([]);
      try {
        const labelsHook = JSON.parse(labelsStr);
        const response = await Api.frame().all({
          dataset_ids: [datasetId],
          job_ids: jobId ? [parseInt(jobId)] : null,
          stream_id: cameraId,
          created_before: frameBeforeDate ? frameBeforeDate : null,
          created_after: frameAfterDate ? frameAfterDate : null,
          label_version_created_before: labellingBeforeDate
            ? labellingBeforeDate
            : null,
          label_version_created_after: labellingAfterDate
            ? labellingAfterDate
            : null,
          mode: mode === "user_latest" ? "any_user_latest" : mode,
          qualified: qualified,
          include_entities: true,
          skip_empty: skipEmpty,
          or_labels: labelsHook,
          include_unlabelled_images: includeUnlabelledImages,
          limit: PAGE_LENGTH,
          offset: offset,
          with_entities: mode === "user_latest" || mode === "auto",
        });
        setFrames(response.data.results);
        setCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    loadImageVersions();
  }, [
    cameraId,
    datasetId,
    frameAfterDate,
    frameBeforeDate,
    includeUnlabelledImages,
    jobId,
    labellingAfterDate,
    labellingBeforeDate,
    labelsStr,
    mode,
    offset,
    qualified,
    setCount,
    setFrames,
    setLoading,
    skipEmpty,
  ]);

  useEffect(() => {
    const loadLabelOptions = async () => {
      setLoadingLabelOptions(true);
      setLabels([]);

      try {
        const response = await Api.labelversion().labels({
          dataset_ids: [datasetId],
          mode: mode,
        });
        setLabelOptions(
          Object.keys(response.data.results).map((key, index) => ({
            label: key,
            value: key,
          })),
        );
      } catch (error) {
        console.error(error);
      }

      setLoadingLabelOptions(false);
    };

    loadLabelOptions();
  }, [datasetId, mode, setLabelOptions, setLabels, setLoadingLabelOptions]);

  return (
    <div style={{ padding: "0 25px 0 25px" }}>
      <h3>Download Dataset</h3>
      <p>
        Generate a zip file with all of the images and entities in this dataset.
        After the zip file is created, you will be able to download it below.
      </p>
      <div style={{ padding: "25px" }}>
        <FormComponent>
          <label>Mode</label>
          <Select
            options={modeOptions}
            defaultValue={modeOptions[0]}
            onChange={(e: Option<LabelVersionMode>) => {
              setMode(e ? e.value : null);
              updateParams();
            }}
            isClearable
          />
          {mode === "user_latest" && (
            <Fragment>
              <label>Qualification</label>
              <Select
                options={qualificationOptions}
                defaultValue={
                  qualificationOptions.filter(
                    (option) => option.value === qualification,
                  )[0]
                }
                onChange={(e: Option<Qualification>) => {
                  setQualification(e ? e.value : null);
                  updateParams();
                }}
              />
            </Fragment>
          )}
          {mode === "user_latest" && (
            <Fragment>
              <label>Labelling Job</label>
              {loadingLabellingJobs ? (
                <Loading />
              ) : (
                <Select
                  isClearable
                  options={jobs}
                  onChange={(e: Option<number>) => {
                    setJobId(e ? e.value : null);
                    updateParams();
                  }}
                />
              )}
            </Fragment>
          )}
          <label>Camera</label>
          {loadingCameras ? (
            <Loading />
          ) : (
            <Select
              isClearable
              options={cameras}
              onChange={(e: Option<string>) => {
                setCameraId(e ? e.value : null);
                updateParams();
              }}
            />
          )}
          <label>Frame created before:</label>
          <input
            type="datetime-local"
            onChange={(e) => {
              setFrameBeforeDate(e.target.value);
              updateParams();
            }}
          />
          <label>Frame created after:</label>
          <input
            type="datetime-local"
            onChange={(e) => {
              setFrameAfterDate(e.target.value);
              updateParams();
            }}
          />
          <label>Labelling created before:</label>
          <input
            type="datetime-local"
            onChange={(e) => {
              setLabellingBeforeDate(e.target.value);
              updateParams();
            }}
          />
          <label>Labelling created after:</label>
          <input
            type="datetime-local"
            onChange={(e) => {
              setLabellingAfterDate(e.target.value);
              updateParams();
            }}
          />
          {loadingLabelOptions ? (
            <Loading />
          ) : (
            <Fragment>
              <label>Detection Labels (OR):</label>
              <CreatableSelect
                value={labels}
                onChange={setLabels}
                options={labelOptions}
                isMulti
                filterOption={(option, rawInput) => {
                  if (option.data.__isNew__) return true;

                  return customFilter(option, rawInput);
                }}
              />
            </Fragment>
          )}
          <label>Include images</label>
          <input
            type="checkbox"
            onChange={(e) => setIncludeImages(e.target.checked)}
            defaultChecked={includeImages}
          />
          <label>Skip images without any entities</label>
          <input
            type="checkbox"
            onChange={(e) => {
              setSkipEmpty(e.target.checked);
              updateParams();
            }}
            defaultChecked={skipEmpty}
            disabled={!includeImages}
          />
          <label>Include unlabelled images</label>
          <input
            type="checkbox"
            onChange={(e) => {
              setIncludeUnlabelledImages(e.target.checked);
              updateParams();
            }}
            defaultChecked={includeUnlabelledImages}
            disabled={!includeImages}
          />
          <label>Keep only entities listed in Detection Labels</label>
          <input
            type="checkbox"
            onChange={(e) => {
              setRemoveNotIncludedEntities(e.target.checked);
              updateParams();
            }}
            defaultChecked={removeNotIncludedEntities}
          />
        </FormComponent>
      </div>
      <Button
        onClick={onGenerateDatasetDownload}
        big
        className={styles.buttonAlign}
        disabled={archiveGenerationInProgress}
      >
        Generate
      </Button>
      {downloadLink && (
        <Button
          onClick={() => window.open(downloadLink)}
          big
          className={styles.buttonAlign}
          disabled={archiveGenerationInProgress}
        >
          Download
        </Button>
      )}
      <TaskStatus
        rawTask={downloadTask}
        statusUpdate={downloadTaskFinished}
        handleError={downloadTaskFailed}
      />
      <div className={styles.downloadPreviewContainer}>
        <h2>Download Preview</h2>
        <div>
          {loading && <Loading />}
          {!loading && frames.length > 0 ? (
            <Fragment>
              {count > PAGE_LENGTH && (
                <Paginator
                  count={count}
                  offset={offset}
                  pageLength={PAGE_LENGTH}
                  disabled={loading}
                />
              )}
              <CardList>
                {frames &&
                  frames.map((frame) => (
                    <Card className={styles.card} key={frame.id}>
                      <CardImage frame={frame} withWrapper={false} />
                    </Card>
                  ))}
              </CardList>
            </Fragment>
          ) : (
            !loading && <Fragment>Nothing to Download</Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
